<template>
  <component-wrapper>
    <grid-container-fi
      :class="{
        'image-quote-fi': true,
        'image-quote-fi--dark': fields.dark?.value,
      }"
    >
      <div class="fi-col-start-1 fi-col-end-13 sm:fi-col-start-2 sm:fi-col-end-12">
        <div class="image-quote-fi__container">
          <div class="image-quote-fi__image">
            <ssr-cloud-image-fi
              v-if="!isEditMode && fields.image?.value"
              :field="fields.image"
              :src="fields.image.value.src"
              :alt="fields?.imageAlt?.value"
              :title="fields?.imageTitle?.value"
              :breakpoints="breakpoints"
              unlimited="773"
              class="image-quote-fi__img"
            />
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-else-if="fields.image"
              class="image-quote-fi__img"
              v-html="fields.image.editable"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <quote-fi
            class="image-quote-fi__quote"
            :fields="fields"
          />
        </div>
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script>
import { SsrCloudImageFi } from 'atoms';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import QuoteFi from 'components/quote-fi/QuoteFi';

export default {
  name: 'ImageQuoteFi',
  components: {
    ComponentWrapper,
    GridContainerFi,
    QuoteFi,
    SsrCloudImageFi,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    breakpoints() {
      return [
        {
          mediaWidth: 480,
          imageWidth: 222,
        },
        {
          mediaWidth: 767,
          imageWidth: 366,
        },
        {
          mediaWidth: 1023,
          imageWidth: 640,
        },
        {
          mediaWidth: 1500,
          imageWidth: 598,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import './image-quote-fi';
</style>
